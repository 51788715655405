<template>
  <card class="card-product">
    <template slot="image">
      <img v-bind:src="image" alt="..." />
    </template>
    <template slot="body">
      <h4 class="card-title">{{ title }}</h4>
      <div class="card-footer">
        <div class="price-container">
          <span class="price opacity-8">€ {{ price }}</span>
        </div>
        <button type="button" class="btn pull-right rounded-circle btn-icon-only btn-icon btn-secondary btn-gold-hoover btn-sm" title="Offrir" @click="$emit('offer', title, price)">
          <span v-if="amount">{{ amount }}</span>
          <i class="ni ni-fat-add" v-else></i>
        </button>
      </div>
    </template>
  </card>
</template>
<script>
export default {
  name: "weddingcard",
  props: ["image", "title", "price", "amount"],
  emits: ["offer"]
};
</script>
<style></style>
