<template>
  <div class="wrapper">
    <div
      class="page-header page-header-small header-filter skew-separator skew-mini"
    >
      <div
        class="page-header-image"
        style="
          background-image: url('/img/wedding.jpg');
          filter: brightness(50%);
        "
      ></div>
      <div class="container pb-0">
        <div class="row">
          <div class="col-lg-6 col-md-7 mx-auto text-center pt-150">
            <h1 class="display-1 text-gold text-hermitage">
              Catherine & Maxime
            </h1>
          </div>
        </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <div class="main mt-0">
      <div class="container text-center">
        <section class="blogs-1">
          <div class="container">
            <div class="row mb-5">
              <div class="col-md-10 mx-auto">
                <p class="lead mt-1 font-italic text-justify">
                  Joignez-vous à nous pour célébrer ce moment magique de nos
                  vies ! Explorez notre liste de mariage où chaque participation
                  contribuera à construire notre avenir ensemble.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div
                  class="col-lg-3 col-md-6"
                  v-for="gift in honeymoon"
                  v-bind:key="gift.title"
                >
                  <WeddingCard
                    v-bind:title="gift.title"
                    v-bind:image="gift.image"
                    v-bind:price="gift.price"
                    v-bind:amount="amount(gift.title)"
                    v-on:offer="offer"
                  ></WeddingCard>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="mx-auto text-center">
              <h1 class="desc my-5 font-weight-bolder text-gold text-hermitage">
                Et pour plus tard...
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div
                  class="col-lg-3 col-md-6"
                  v-for="gift in housing"
                  v-bind:key="gift.title"
                >
                  <WeddingCard
                    v-bind:title="gift.title"
                    v-bind:image="gift.image"
                    v-bind:price="gift.price"
                    v-bind:amount="amount(gift.title)"
                    v-on:offer="offer"
                  ></WeddingCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="testimonials-1 bg-secondary py-5 skew-separator skew-top">
        <div class="container">
          <div class="row text-left d-flex justify-content-between align-items-center">
            <div class="col-12 col-md-5 mt-5">
              <h3 class="text-primary">• • •</h3>
              <h4 class="lead text-justify">
                Pour un peu de suspense et de chance, une urne sera à votre
                disposition le jour J pour y déposer des billets à gratter
                <i>"Win for Life"</i>.
              </h4>
            </div>
            <div class="hidden-md-and-up col-3"></div>
            <div class="col-6 col-md-3">
                <img src="img/pages/win-for-life.png" class="img-fluid"/>
            </div>
            <div class="hidden-md-and-up col-3"></div>
          </div>
        </div>
        <div class="container mt-5" v-if="gifts.length > 0" id="selection" ref="selection">
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
              <div class="container">
                <h3 class="title mt-3">Votre sélection</h3>
                <div class="row">
                  <div class="card">
                    <div class="card-body">
                      <div
                        class="media align-items-center mb-3"
                        v-for="gift in gifts"
                        v-bind:key="gift.title"
                      >
                        <div class="col-md-3 col-4">
                          <img
                            v-bind:src="image(gift.title)"
                            alt="Rounded image"
                            class="img-fluid"
                          />
                        </div>
                        <div class="media-body">
                          <h2 class="h3">{{ gift.title }}</h2>
                          <p class="d-block opacity-8">
                            {{ gift.amount }} x € {{ price(gift.title) }}
                          </p>
                        </div>
                      </div>
                      <hr class="line-info mb-3" />
                      <div class="media align-items-center">
                        <h3 class="h6">
                          Montant de votre cadeau: €
                          {{
                            gifts.reduce(
                              (previous, current) =>
                                previous +
                                current.amount * price(current.title),
                              0
                            )
                          }}
                        </h3>
                      </div>
                      <hr class="line-info mb-3" />
                      <div class="media">
                        <h3 class="h6">
                          Pour finaliser votre sélection, nous vous invitons à
                          procéder au virement dans notre urne digitale au nom
                          de
                          <span class="text-monospace text-nowrap"
                            >THIEBAUT-BEMELMANS M & C</span
                          >, sur le compte
                          <span class="text-monospace text-nowrap"
                            >BE07 7440 9627 9866</span
                          >. Nous vous remercions chaleureusement.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      v-if="gifts.length"
      class="btn rounded-circle btn-icon-only btn-icon btn-gold btn-bottom mr-3 mb-3 btn-lg"
      title="Offrir"
      @click="selection"
    >
      <i class="fa font-weight-bold fa-angle-down"></i>
    </button>
  </div>
</template>
<script>
import "bootstrap-vue/dist/bootstrap-vue.min.css";
import WeddingCard from "@/components/WeddingCard.vue";
import { reactive } from "vue";

const gifts = reactive([]);

export default {
  bodyClass: "ecommerce-page",
  components: {
    WeddingCard,
  },
  setup() {
    return { gifts };
  },
  data() {
    return {
      honeymoon: [
        {
          title: "Billets d'avion vers Zanzibar",
          image: "img/wedding/ticket.png",
          price: 150,
        },
        {
          title: "Une valise pour les maillots",
          image: "img/wedding/valises.jpg",
          price: 75,
        },
        {
          title: "Nuitée en lodge avec déjeuner",
          image: "img/wedding/lodge.jpg",
          price: 100,
        },
        {
          title: "Sortie en kayak avec pagaies",
          image: "img/wedding/kayak.jpg",
          price: 30,
        },
        {
          title: "Équipement pour Maxime",
          image: "img/wedding/sand-castle.jpg",
          price: 15,
        },
        {
          title: "Jus de fruits pour Catherine",
          image: "img/wedding/cocktails.png",
          price: 8,
        },
        {
          title: "Exploration sous-marine",
          image: "img/wedding/snorkling.jpg",
          price: 80,
        },
        {
          title: "Sortie Safari, girafe en option",
          image: "img/wedding/safari.jpg",
          price: 200,
        },
      ],
      housing: [
        {
          title: "Partie de terrain à bâtir",
          image: "img/wedding/terrain.jpg",
          price: 250,
        },
        {
          title: "Du carrelage pour la cuisine",
          image: "img/wedding/carrelage.jpg",
          price: 40,
        },
        {
          title: "Une porte d'entrée",
          image: "img/wedding/door.jpg",
          price: 90,
        },
        {
          title: "De l'électricité conforme",
          image: "img/wedding/electricite.jpg",
          price: 75,
        },
        {
          title: "Un frigo pour se rafraîchir",
          image: "img/wedding/fridge.jpg",
          price: 200,
        },
        {
          title: "Une douche pour Catherine",
          image: "img/wedding/douche.jpg",
          price: 50,
        },
        {
          title: "Une baignoire pour Maxime",
          image: "img/wedding/baignoire.png",
          price: 100,
        },
        {
          title: "Un divan pour se reposer",
          image: "img/wedding/meubles.jpg",
          price: 120,
        },
      ],
    };
  },
  methods: {
    offer: function (title) {
      var index = gifts.findIndex((gift) => gift.title === title);
      if (index >= 0) {
        var gift = gifts[index];
        gift.amount++;
        gifts.splice(index, 1, gift);
      } else {
        gifts.splice(gifts.length, 0, {
          title,
          amount: 1,
        });
      }
    },
    image: function (title) {
      var match = this.housing.find((item) => item.title === title);
      if (match) {
        return match.image;
      }
      match = this.honeymoon.find((item) => item.title === title);
      if (match) {
        return match.image;
      }
    },
    price: function (title) {
      var match = this.housing.find((item) => item.title === title);
      if (match) {
        return match.price;
      }
      match = this.honeymoon.find((item) => item.title === title);
      if (match) {
        return match.price;
      }
    },
    amount: function (title) {
      var match = this.gifts.find((item) => item.title === title);
      if (match) {
        return match.amount;
      }
    },
    selection: function() {
      this.$refs.selection.scrollIntoView({ behavior: 'smooth' });
    }
  },
};
</script>
<style>
.ecommerce-page a.carousel-control-prev,
.ecommerce-page a.carousel-control-next {
  z-index: 9999;
}
</style>
