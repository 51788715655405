<template>
  <footer class="footer">
    <div class="container">
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-12">
          <ul class="nav nav-footer justify-content-start">
            <li class="nav-item">
              <a href="/" class="nav-link" target="_blank"
                >Catherine & Maxime</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
